import "./registerServiceWorker";
import {
  LOGIN_BY_TOKEN,
  LOGIN_FROM_ADMIN
} from "@/constants/actions";

export let Vue: Vue;

const getUserData = async (): Promise<void> => {
  const userAlreadyLoggedIn = Vue.$store.getters["authentication/isRefreshTokenSet"];
  const isAnotherLoginFromAdmin = Vue.$route.fullPath.includes("impersonate");
  const isAlreadyLoggedInFromAdmin = Vue.$store.getters["authentication/isSelf"] === false;
  const isUserLoggedInByHimself = Vue.$store.getters["authentication/isSelf"];

  if (userAlreadyLoggedIn) {
    if (isAlreadyLoggedInFromAdmin && !isAnotherLoginFromAdmin) {
      await Vue.$store.dispatch(`authentication/${ LOGIN_FROM_ADMIN }`);
    } else if (isUserLoggedInByHimself) {
      await Vue.$store.dispatch(`authentication/${ LOGIN_BY_TOKEN }`);
    }
  }
};

if (process.env.VUE_APP_SENTRY_ON === "true") {
  import("@/plugins/sentry").then(module => {
    module.default();
  });
}

if (process.env.NODE_ENV === "development") {
  const host = location.hostname;

  if (host === "adnetwork.dev") {
    import("./appAdmin/main").then((data) => {
      Vue = data.default;
      getUserData();
    });
  } else if (host === "partner.adnetwork.dev") {
    import("./appPartner/main").then((data) => {
      Vue = data.default;
      getUserData();
    });
  }
} else {
  if (process.env.VUE_APP_BUILD_TYPE === "ADMIN") {
    import("./appAdmin/main").then((data) => {
      Vue = data.default;
      getUserData();
    });
  } else if (process.env.VUE_APP_BUILD_TYPE === "PUBLISHER") {
    import("./appPartner/main").then((data) => {
      Vue = data.default;
      getUserData();
    });
  }
}
