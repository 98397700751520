// common
export const INITIALIZE_TABLE = "INITIALIZE_TABLE";
export const INITIALIZE_FILTERS = "INITIALIZE_FILTERS";
export const SET_TIMEOUT_FOR_INITIALIZING_MULTIPLE_FILTERS = "SET_TIMEOUT_FOR_INITIALIZING_MULTIPLE_FILTERS";
export const INITIALIZE_GROUPS = "INITIALIZE_GROUPS";
export const UPDATE_PAGINATION_IF_PAGE_EMPTY = "UPDATE_PAGINATION_IF_PAGE_EMPTY";

// initialization
export const INITIALIZATION = "INITIALIZATION";

// authentication
export const LOGIN_BY_TOKEN = "LOGIN_BY_TOKEN";
export const GET_TOKEN = "GET_TOKEN";
export const UPDATE_TOKEN_TIMER = "UPDATE_TOKEN_TIMER";
export const LOGIN = "LOGIN";
export const SIGN_IN_AS_PUBLISHER_OR_ADVERTISER = "SIGN_IN_AS_PUBLISHER_OR_ADVERTISER";
export const SIGN_IN_AS_ADMIN = "SIGN_IN_AS_ADMIN";
export const LOGIN_FROM_ADMIN = "LOGIN_FROM_ADMIN";
export const RETURN_TO_SELF = "RETURN_TO_SELF";
export const EMPTY_ADMIN = "EMPTY_ADMIN";
export const EMPTY_ADVERTISER = "EMPTY_ADVERTISER";
export const EMPTY_PUBLISHER = "EMPTY_PUBLISHER";
export const EMPTY_LOCAL_STORAGE = "EMPTY_LOCAL_STORAGE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_USER_FROM_WRONG_CABINET = "LOGOUT_USER_FROM_WRONG_CABINET";
export const LOGOUT_AND_REDIRECT_TO_SIGN_IN_PAGE = "LOGOUT_AND_REDIRECT_TO_SIGN_IN_PAGE";
export const CONFIRM_USER_REGISTRATION = "CONFIRM_USER_REGISTRATION";
export const SEND_MAIL_CONFIRMATION = "SEND_MAIL_CONFIRMATION";
export const SEND_FORGET_PASSWORD_LINK = "SEND_FORGET_PASSWORD_LINK";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";

// File exports
export const EXPORT_TO_FILE = "EXPORT_TO_FILE";
export const GET_FILE_EXPORT = "GET_FILE_EXPORT";
export const SET_FILE_EXPORT_TIMEOUT = "SET_FILE_EXPORT_TIMEOUT";
export const CLEAR_FILE_EXPORT_TIMEOUT = "CLEAR_FILE_EXPORT_TIMEOUT";

// Balance
export const GET_BALANCE = "GET_BALANCE";
export const GET_BALANCE_MANUALLY = "GET_BALANCE_MANUALLY";
export const SET_BALANCE_INTERVAL = "SET_BALANCE_INTERVAL";
export const CLEAR_BALANCE_INTERVAL = "CLEAR_BALANCE_INTERVAL";
export const GET_BALANCE_REFERRAL_EARNINGS = "GET_BALANCE_REFERRAL_EARNINGS";

// Profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_REQUISITES = "GET_PROFILE_REQUISITES";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const UPDATE_PROFILE_PREFERENCES = "UPDATE_PROFILE_PREFERENCES";
export const SET_PROFILE_PREFERENCES = "SET_PROFILE_PREFERENCES";
export const GET_PROFILE_PREFERENCES_PRESETS = "GET_PROFILE_PREFERENCES_PRESETS";
export const GET_INITIAL_PROFILE_PREFERENCES_PRESETS = "GET_INITIAL_PROFILE_PREFERENCES_PRESETS";
export const CREATE_PROFILE_PREFERENCES_PRESET = "CREATE_PROFILE_PREFERENCES_PRESET";
export const EDIT_PROFILE_PREFERENCES_PRESET = "EDIT_PROFILE_PREFERENCES_PRESET";
export const UPDATE_PROFILE_PREFERENCES_SELECTED_PRESET = "UPDATE_PROFILE_PREFERENCES_SELECTED_PRESET";
export const REMOVE_PROFILE_PREFERENCES_PRESET = "REMOVE_PROFILE_PREFERENCES_PRESET";

// --------------- lists --------------- //

// ssp list
export const GET_SSP_LIST = "GET_SSP_LIST";
export const GET_INITIAL_SSP = "GET_INITIAL_SSP";
export const UPDATE_SSP_LIST = "UPDATE_SSP_LIST";

// ssp endpoints list
export const GET_SSP_ENDPOINTS_LIST = "GET_SSP_ENDPOINTS_LIST";
export const GET_INITIAL_SSP_ENDPOINTS = "GET_INITIAL_SSP_ENDPOINTS";
export const UPDATE_SSP_ENDPOINTS_LIST = "UPDATE_SSP_ENDPOINTS_LIST";

// dsp list
export const GET_DSP_LIST = "GET_DSP_LIST";
export const GET_INITIAL_DSP = "GET_INITIAL_DSP";
export const UPDATE_DSP_LIST = "UPDATE_DSP_LIST";

// dsp endpoint list
export const GET_DSP_ENDPOINT_LIST = "GET_DSP_ENDPOINT_LIST";
export const GET_INITIAL_DSP_ENDPOINT = "GET_INITIAL_DSP_ENDPOINT";
export const UPDATE_DSP_ENDPOINT_LIST = "UPDATE_DSP_ENDPOINT_LIST";

// campaigns list
export const GET_CAMPAIGNS_LIST = "GET_CAMPAIGNS_LIST";
export const GET_INITIAL_CAMPAIGNS = "GET_INITIAL_CAMPAIGNS";
export const UPDATE_CAMPAIGNS_LIST = "UPDATE_CAMPAIGNS_LIST";

// accessLists list
export const GET_ACCESS_LISTS_LIST = "GET_ACCESS_LISTS_LIST";
export const GET_INITIAL_ACCESS_LISTS = "GET_INITIAL_ACCESS_LISTS";
export const UPDATE_ACCESS_LISTS_LIST = "UPDATE_ACCESS_LISTS_LIST";
export const SEARCH_ACCESS_LISTS_LIST_ITEMS = "SEARCH_ACCESS_LISTS_LIST_ITEMS";

// ad automizer segment subject list
export const GET_AD_AUTOMIZER_SEGMENT_SUBJECT_LIST = "GET_AD_AUTOMIZER_SEGMENT_SUBJECT_LIST";

// ad automizer segment apply to list
export const GET_AD_AUTOMIZER_SEGMENT_APPLY_TO_LIST = "GET_AD_AUTOMIZER_SEGMENT_APPLY_TO_LIST";

// ad automizers all lists
export const GET_AD_AUTOMIZER_ALL_LISTS = "GET_AD_AUTOMIZER_ALL_LISTS";

// ad automizers list
export const GET_AD_AUTOMIZERS_LIST = "GET_AD_AUTOMIZERS_LIST";
export const GET_INITIAL_AD_AUTOMIZERS = "GET_INITIAL_AD_AUTOMIZERS";
export const UPDATE_AD_AUTOMIZERS_LIST = "UPDATE_AD_AUTOMIZERS_LIST";

// campaign affiliate partners list
export const GET_CAMPAIGN_AFFILIATE_PARTNERS_LIST = "GET_CAMPAIGN_AFFILIATE_PARTNERS_LIST";
export const GET_INITIAL_CAMPAIGN_AFFILIATE_PARTNERS = "GET_INITIAL_CAMPAIGN_AFFILIATE_PARTNERS";
export const UPDATE_CAMPAIGN_AFFILIATE_PARTNERS_LIST = "UPDATE_CAMPAIGN_AFFILIATE_PARTNERS_LIST";

// browser list
export const GET_CAMPAIGN_BROWSER_LIST = "GET_CAMPAIGN_BROWSER_LIST";

// campaign os list
export const GET_CAMPAIGN_OS_LIST = "GET_CAMPAIGN_OS_LIST";

// device types list
export const GET_CAMPAIGN_DEVICES_TYPE_LIST = "GET_CAMPAIGN_DEVICES_TYPE_LIST";

// time measurement list
export const GET_TIME_MEASUREMENTS_LIST = "GET_TIME_MEASUREMENTS_LIST";

// sites list
export const GET_SITES_LIST = "GET_SITES_LIST";
export const GET_INITIAL_SITES = "GET_INITIAL_SITES";
export const UPDATE_SITES_LIST = "UPDATE_SITES_LIST";

// widgets list
export const GET_AD_ZONES_LIST = "GET_AD_ZONES_LIST";
export const GET_INITIAL_AD_ZONES = "GET_INITIAL_AD_ZONES";
export const UPDATE_AD_ZONES_LIST = "UPDATE_AD_ZONES_LIST";

// timeZones list
export const GET_TIME_ZONES_LIST = "GET_TIME_ZONES_LIST";

// geo list
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";
export const GET_COUNTRY_REGIONS_LIST = "GET_COUNTRY_REGIONS_LIST";
export const GET_REGION_CITIES_LIST = "GET_REGION_CITIES_LIST";
export const GET_GEO_LIST_BY_IDS = "GET_GEO_LIST_BY_IDS";

// languages list
export const GET_LANGUAGES_LIST = "GET_LANGUAGES_LIST";

// iab categories list
export const GET_IAB_LIST = "GET_IAB_LIST";

// payment systems list
export const GET_PAYMENT_SYSTEMS_LIST = "GET_PAYMENT_SYSTEMS_LIST";
export const GET_INITIAL_PAYMENT_SYSTEMS = "GET_INITIAL_PAYMENT_SYSTEMS";
export const UPDATE_PAYMENT_SYSTEMS_LIST = "UPDATE_PAYMENT_SYSTEMS_LIST";

// re-postback templates list
export const GET_REPOSTBACK_TEMPLATES_LIST = "GET_REPOSTBACK_TEMPLATES_LIST";
export const GET_INITIAL_REPOSTBACK_TEMPLATES = "GET_INITIAL_REPOSTBACK_TEMPLATES";
export const UPDATE_REPOSTBACK_TEMPLATES_LIST = "UPDATE_REPOSTBACK_TEMPLATES_LIST";
